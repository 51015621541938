import { CarBrandModel } from "../models/support/carModelsByBrand.model";

export enum EVehicleIdentError {
  searchByVin = "searchByVin",
  searchByPlate = "searchByPlate",
  vin = "vin",
  make = "make",
  model = "model",
  engine = "engine",
  year = "year",
  variant = "variant",
  mileage = "mileage",
}
export type VehicleIdentFormErrors = Record<EVehicleIdentError, string>;

export enum EVehicleIdentLoadingField {
  searchMethods = "searchMethods",
  make = "make",
  model = "model",
  engine = "engine",
  year = "year",
  variant = "variant",
  loader = "loader",
}
export type VehicleIdentLoadingFields = Record<
  EVehicleIdentLoadingField,
  boolean
>;

export enum EVehicleSearchModal {
  commonError = "commonError",
  vehicleFound = "vehicleFound",
  vehicleNotFound = "vehicleNotFound",
}
export type VehicleSearchModalsStatus = Record<EVehicleSearchModal, boolean>;

export enum EVehicleIdentDisabledField {
  "make" = "make",
  "model" = "model",
  "engine" = "engine",
  "year" = "year",
  "variant" = "variant",
  "dvci" = "dvci",
}

export enum ERequestDetailsError {
  technician = "technician",
  vci = "vci",
  serviceType = "serviceType",
  category = "category",
  subject = "subject",
  diagToolSerial = "diagToolSerial",
  vciSerial = "vciSerial",
  repairOrder = "repairOrder",
  mileage = "mileage",
  comments = "comments",
}
export type RequestDetailsFormErrors = Record<ERequestDetailsError, string>;

export enum ESelectScheduledTimeSlotError {
  date = "date",
  time = "time",
}
export type ScheduledTimSlotFormErrors = Record<
  ESelectScheduledTimeSlotError,
  string
>;

export enum ESearchVehicleMode {
  "vin" = "vin",
  "licensePlate" = "licensePlate",
}

export enum ERequestDetailsField {
  diagToolSerial = "diagToolSerial",
  vciSerial = "vciSerial",
  repairOrder = "repairOrder",
  mileage = "mileage",
}
export type UsRequestDetailsFields = Record<ERequestDetailsField, string>;

export interface RelevantCarModel {
  score: number;
  model: CarBrandModel;
}

export interface SpecialElectricCar {
  model: string;
  electricCars: string[];
}
