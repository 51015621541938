export const MAB_VHC_TRANSLATIONS_DA_DK = {
	MAB_VHC_ContactUs_Headline: "Kontakt os",
	MAB_VHC_ContactUs_HowCanWeHelp: "Hvordan kan vi hjælpe dig?",
	MAB_VHC_ContactUs_Email_Headline: "Send en e-mail!",
	MAB_VHC_ContactUs_Email_Description: "Send os din besked ved at udfylde formularen nedenfor. En af vores rådgivere vil meget gerne komme i kontakt med dig.",
	MAB_VHC_ContactUs_Email_InputName: "Navn*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Navn er påkrævet.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Navnet overskrider den tilladte grænse på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputEmail: "E-mail adresse*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "E-mailadresse er påkrævet.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "E-mailadressen overskrider den tilladte grænse på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Ugyldigt e-mail-format.",
	MAB_VHC_ContactUs_Email_InputCompany: "Virksomhedsoplysninger*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Virksomhedsoplysninger er påkrævet.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Virksomhedsoplysninger overskrider den tilladte grænse på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adresse*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Adresse er påkrævet.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Adressen overskrider den tilladte grænse på 250 tegn.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Postnummer*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Postnummer er påkrævet.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Postnummeret overskrider den tilladte grænse på 25 tegn.",
	MAB_VHC_ContactUs_Email_InputCity: "By*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "By er påkrævet.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "By overskrider den tilladte grænse på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputCountry: "Land*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Land er påkrævet.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Landet overskrider den tilladte grænse på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Certifikat / Faktura nr",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Attest / Faktura nr. er påkrævet.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Attest / Faktura nr. overskrider den tilladte grænse på 50 tegn.",
	MAB_VHC_ContactUs_Email_InputReason: "Grund til at kontakte os*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Kort beskrivelse*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Kort beskrivelse er påkrævet.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Kort beskrivelse overskrider den tilladte grænse på 100 tegn.",
	MAB_VHC_ContactUs_Email_InputDescription: "Beskrivelse*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Beskrivelse er påkrævet.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Beskrivelsen overskrider den tilladte grænse på 1000 tegn.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Spørgsmål*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Spørgsmål er påkrævet.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Spørgsmålet overskrider den tilladte grænse på 100 tegn.",
	MAB_VHC_ContactUs_Email_Urgency: "Uopsættelighed",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Jeg kan ikke fortsætte med at arbejde.",
	MAB_VHC_ContactUs_Email_DataProtection: "Databeskyttelsesmeddelelse*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Jeg har læst <a href='{{data_protection_link}}' target='_blank'>privatlivspolitikken.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Sikkerhedstjek",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Captcha-tekstsvaret, du indtastede, er forkert.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Indtast venligst de korrekte tegn, du ser i boksen ovenfor.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Tak fordi du kontakter os! Din besked er sendt, og vi vender tilbage til dig snarest.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Vi kunne ikke sende e-mailen på grund af et problem. Sørg for, at oplysningerne er korrekte, og prøv igen.",
	MAB_VHC_ContactUs_Reasons_Incident: "Hændelse",
	MAB_VHC_ContactUs_Reasons_Bug: "Bug",
	MAB_VHC_ContactUs_Reasons_Question: "Spørgsmål",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Feedback og funktionsanmodning",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Har du problemer? Eller er der noget, der forhindrer dig i at fortsætte med at arbejde? Tøv ikke med at kontakte os.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Procestrin effektueret af hændelsen*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Køretøjsidentifikation",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Adgang til tjenesten",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Start af tjenesten",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Udlæsning af køretøjsdata",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Kasse og fakturering",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Forhåndsvisning af dokument",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Dokumentlevering",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Andre",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Har du fundet en fejl? Du er velkommen til at dele den med os.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Procestrin effektueret af hændelsen*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Har du spørgsmål til vores service? Dit spørgsmål var ikke dækket af vores <a href='{{faq_link}}' target='_blank'>Ofte stillede spørgsmål</a>? Spørg vores support.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Emne*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Serviceindhold",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Køretøjsdækning",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Landedækning",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Kasseproces",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Prissætning",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware/software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Andre",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Mangler du en bestemt funktion? Eller vil du give direkte feedback? Du er velkommen til at tage fat.",
	MAB_VHC_SubmitButton: "Indsend",
	MAB_VHC_CloseButton: "Tæt",
};
