export const MAB_VHC_TRANSLATIONS_PL_PL = {
	MAB_VHC_ContactUs_Headline: "Skontaktuj się z nami",
	MAB_VHC_ContactUs_HowCanWeHelp: "Jak możemy ci pomóc?",
	MAB_VHC_ContactUs_Email_Headline: "Wyślij nam e-mail!",
	MAB_VHC_ContactUs_Email_Description: "Wyślij nam swoją wiadomość wypełniając poniższy formularz. Jeden z naszych pracowników chętnie się z tobą skontaktuje.",
	MAB_VHC_ContactUs_Email_InputName: "Nazwisko*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Nazwisko jest wymagane.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Nazwisko przekracza dozwolony limit 100 znaków.",
	MAB_VHC_ContactUs_Email_InputEmail: "Adres e-mail*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "Adres e-mail jest wymagany.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "Adres e-mail przekracza dozwolony limit 100 znaków.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Nieprawidłowy format adresu e-mail.",
	MAB_VHC_ContactUs_Email_InputCompany: "Informacja o firmie*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Informacja o firmie jest wymagana",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Informacja o firmie przekracza dozwolony limit 100 znaków.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adres*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Adres jest wymagany.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Adres przekracza dozwolony limit 250 znaków.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Kod pocztowy*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Kod pocztowy jest wymagany.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Kod pocztowy przekracza dozwolony limit 25 znaków.",
	MAB_VHC_ContactUs_Email_InputCity: "Miasto*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Miasto jest wymagane.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "Nazwa miasta przekracza dozwolony limit 100 znaków.",
	MAB_VHC_ContactUs_Email_InputCountry: "Kraj*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Kraj jest wymagany.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Nazwa kraju przekracza dozwolony limit 100 znaków.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Nr raportu / faktury",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Nr raportu / faktury jest wymagany.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Nr raportu / faktury przekracza dozwolony limit 50 znaków.",
	MAB_VHC_ContactUs_Email_InputReason: "Powód skontaktowania się z nami*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Krótki opis*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Krótki opis jest wymagany.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Krótki opis przekracza dozwolony limit 100 znaków.",
	MAB_VHC_ContactUs_Email_InputDescription: "Opis*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Opis jest wymagany.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Opis przekracza dozwolony limit 1000 znaków.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Pytanie*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Pytanie jest wymagane.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Pytanie przekracza dozwolony limit 100 znaków.",
	MAB_VHC_ContactUs_Email_Urgency: "Pilność",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Nie mogę kontynuować pracy.",
	MAB_VHC_ContactUs_Email_DataProtection: "Informacja o ochronie danych*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Zapoznałem się z <a href='{{data_protection_link}}' target='_blank'>Polityką prywatności.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Kontrola bezpieczeństwa",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Wprowadzona odpowiedź jest nieprawidłowa.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Wpisz poprawnie znaki, które widzisz w polu powyżej.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Dziękujemy za skontaktowanie się z nami! Twoja wiadomość została wysłana i wkrótce się z Tobą skontaktujemy.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Nie mogliśmy wysłać wiadomości e-mail z powodu błędu. Upewnij się, że dane są poprawne i spróbuj ponownie.",
	MAB_VHC_ContactUs_Reasons_Incident: "Zdarzenie",
	MAB_VHC_ContactUs_Reasons_Bug: "Błąd",
	MAB_VHC_ContactUs_Reasons_Question: "Pytanie",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Opinia i prośba o dodanie funkcji",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Czy masz problemy? A może coś uniemożliwia Ci dalszą pracę? Nie wahaj się z nami skontaktować.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Etap procesu, na który miało wpływ zdarzenie*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Identyfikacja pojazdu",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Dostęp do usługi",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Uruchomienie usługi",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Odczyt danych pojazdu",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Płatność i fakturowanie",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Podgląd dokumentu",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Dostarczanie dokumentu",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Inne",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Znalazłeś błąd? Prosimy, podziel się nim z nami.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Etap procesu, na który miało wpływ zdarzenie*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Masz pytanie dotyczące naszej usługi? Czy Twoje pytanie nie jest ujęte w naszych <a href='{{faq_link}}' target='_blank'>FAQ</a>? Zapytaj nasz dział wsparcia.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Temat*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Zawartość usługi",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Zakres ujętych pojazdów",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Zakres ujętych krajów",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Proces realizacji transakcji",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Cennik",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware / software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Inne",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Czy brakuje Ci określonej funkcji? A może chcesz przekazać bezpośrednią opinię? Skontaktuj się z nami.",
	MAB_VHC_SubmitButton: "Prześlij",
	MAB_VHC_CloseButton: "Zamknij",
};
