export enum AdsCameraMode {
  FRONT = "user",
  REAR = "environment",
}

export interface AdsCameraCapability {
  facingMode: AdsCameraMode[];
}

export interface AdsCamera {
  label: string;
  deviceId: string;
  capability: AdsCameraCapability;
}
