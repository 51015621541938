import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_DE_DE } from "./de-DE/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_DA } from "./da/translations";
import { TRANSLATIONS_FI } from "./fi/translations";
import { TRANSLATIONS_DE_AT } from "./de-AT/translations";
import { TRANSLATIONS_DE_CH } from "./de-CH/translations";
import { TRANSLATIONS_ES_ES } from "./es-ES/translations";
import { TRANSLATIONS_FR_BE } from "./fr-BE/translations";
import { TRANSLATIONS_NL_BE } from "./nl-BE/translations";
import { TRANSLATIONS_NL_NL } from "./nl-NL/translations";
import { TRANSLATIONS_SV_SE } from "./sv-SE/translations";
import { TRANSLATIONS_NB_NO } from "./nb-NO/translations";
import { TRANSLATIONS_FR_LU } from "./fr-LU/translations";
import { TRANSLATIONS_FR_FR } from "./fr-FR/translations";
import { TRANSLATIONS_EN_FR } from "./en-FR/translations";
import { TRANSLATIONS_EN_ES } from "./en-ES/translations";
import { TRANSLATIONS_EN_AT } from "./en-AT/translations";
import { TRANSLATIONS_EN_BE } from "./en-BE/translations";
import { TRANSLATIONS_EN_CH } from "./en-CH/translations";
import { TRANSLATIONS_EN_DE } from "./en-DE/translations";
import { TRANSLATIONS_EN_DK } from "./en-DK/translations";
import { TRANSLATIONS_EN_FI } from "./en-FI/translations";
import { TRANSLATIONS_EN_LU } from "./en-LU/translations";
import { TRANSLATIONS_EN_NL } from "./en-NL/translations";
import { TRANSLATIONS_EN_NO } from "./en-NO/translations";
import { TRANSLATIONS_EN_SE } from "./en-SE/translations";
import { TRANSLATIONS_FR_CH } from "./fr-CH/translations";
import { TRANSLATIONS_IT_CH } from "./it-CH/translations";
import { TRANSLATIONS_CS_CZ } from "./cs-CZ/translations";
import { TRANSLATIONS_EN_CZ } from "./en-CZ/translations";
import { TRANSLATIONS_IT_IT } from "./it-IT/translations";
import { TRANSLATIONS_EN_IT } from "./en-IT/translations";
import { TRANSLATIONS_PL_PL } from "./pl-PL/translations";
import { TRANSLATIONS_EN_PL } from "./en-PL/translations";
import { TRANSLATIONS_SK_SK } from "./sk-SK/translations";
import { TRANSLATIONS_EN_SK } from "./en-SK/translations";
import { TRANSLATIONS_EN_GB } from "./en-GB/translations";
import { TRANSLATIONS_EN_CN } from "./en-CN/translations";
import { TRANSLATIONS_EN_JP } from "./en-JP/translations";
import { TRANSLATIONS_JA_JP } from "./ja-JP/translations";
import { TRANSLATIONS_ZH_CN } from "./zh-CN/translations";
import { TRANSLATIONS_EN_US } from "./en-US/translations";
import { TRANSLATIONS_ES_US } from "./es-US/translations";
import { TRANSLATIONS_EN_LT } from "./en-LT/translations";
import { TRANSLATIONS_LT_LT } from "./lt-LT/translations";
import { TRANSLATIONS_EN_LV } from "./en-LV/translations";
import { TRANSLATIONS_LV_LV } from "./lv-LV/translations";
import { TRANSLATIONS_EN_EE } from "./en-EE/translations";
import { TRANSLATIONS_ET_EE } from "./et-EE/translations";
import { TRANSLATIONS_EN_PT } from './en-PT/translations';
import { TRANSLATIONS_PT_PT } from './pt-PT/translations';
import { MAB_VHC_TRANSLATIONS_DE_DE } from "./vhc/de-DE/translations";
import { MAB_VHC_TRANSLATIONS_EN } from "./vhc/en/translations";
import { MAB_VHC_TRANSLATIONS_ES_ES } from "./vhc/es-ES/translations";
import { MAB_VHC_TRANSLATIONS_NL_NL } from "./vhc/nl-NL/translations";
import { MAB_VHC_TRANSLATIONS_NB_NO } from "./vhc/nb-NO/translations";
import { MAB_VHC_TRANSLATIONS_SV_SE } from "./vhc/sv-SE/translations";
import { MAB_VHC_TRANSLATIONS_FR_FR } from "./vhc/fr-FR/translations";
import { MAB_VHC_TRANSLATIONS_CS_CZ } from "./vhc/cs-CZ/translations";
import { MAB_VHC_TRANSLATIONS_IT_IT } from "./vhc/it-IT/translations";
import { MAB_VHC_TRANSLATIONS_PL_PL } from "./vhc/pl-PL/translations";
import { MAB_VHC_TRANSLATIONS_PT_PT } from "./vhc/pt-PT/translations";
import { MAB_VHC_TRANSLATIONS_BG_BG } from "./vhc/bg-BG/translations";
import { MAB_VHC_TRANSLATIONS_DA_DK } from "./vhc/da-DK/translations";
import { MAB_VHC_TRANSLATIONS_FI_FI } from "./vhc/fi-FI/translations";
import { MAB_VHC_TRANSLATIONS_HR_HR } from "./vhc/hr-HR/translations";
import { MAB_VHC_TRANSLATIONS_HU_HU } from "./vhc/hu-HU/translations";
import { MAB_VHC_TRANSLATIONS_RO_RO } from "./vhc/ro-RO/translations";
import { MAB_VHC_TRANSLATIONS_TR_TR } from "./vhc/tr-TR/translations";

export const DEFAULT_LANGUAGE = 'en'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      "bg-BG": {
        translation: MAB_VHC_TRANSLATIONS_BG_BG
      },
      "bg": {
        translation: MAB_VHC_TRANSLATIONS_BG_BG
      },
      "de-AT": {
        translation: TRANSLATIONS_DE_AT
      },
      "en-AT": {
        translation: {
          ...TRANSLATIONS_EN_AT,
        }
      },
      "de-CH": {
        translation: {
          ...TRANSLATIONS_DE_CH,
        }
      },
      "en-CH": {
        translation: {
          ...TRANSLATIONS_EN_CH,
        }
      },
      "fr-CH": {
        translation: {
          ...TRANSLATIONS_FR_CH,
        }
      },
      "it-CH": {
        translation: {
          ...TRANSLATIONS_IT_CH,
        }
      },
      "da": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_DA_DK,
          ...TRANSLATIONS_DA,
        }
      },
      "da-DK": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_DA_DK,
          ...TRANSLATIONS_DA,
        }
      },
      "en-DK": {
        translation: {
          ...TRANSLATIONS_EN_DK,
        }
      },
      "de": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_DE_DE,
          ...TRANSLATIONS_DE_DE,
        }
      },
      "de-DE": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_DE_DE,
          ...TRANSLATIONS_DE_DE,
        }
      },
      "en-DE": {
        translation: {
          ...TRANSLATIONS_EN_DE,
        }
      },
      [DEFAULT_LANGUAGE]: {
        translation: {
          ...MAB_VHC_TRANSLATIONS_EN,
          ...TRANSLATIONS_EN,
        }
      },
      "en-GB": {
        translation: {
          ...TRANSLATIONS_EN_GB,
        }
      },
      "es-ES": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_ES_ES,
          ...TRANSLATIONS_ES_ES,
        }
      },
      "es": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_ES_ES,
          ...TRANSLATIONS_ES_ES,
        }
      },
      "en-ES": {
        translation: {
          ...TRANSLATIONS_EN_ES,
        }
      },
      "fi": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_FI_FI,
          ...TRANSLATIONS_FI,
        }
      },
      "fi-FI": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_FI_FI,
          ...TRANSLATIONS_FI,
        }
      },
      "en-FI": {
        translation: {
          ...TRANSLATIONS_EN_FI,
        }
      },
      "fr-BE": {
        translation: {
          ...TRANSLATIONS_FR_BE,
        }
      },
      "nl-BE": {
        translation: {
          ...TRANSLATIONS_NL_BE,
        }
      },
      "en-BE": {
        translation: {
          ...TRANSLATIONS_EN_BE,
        }
      },
      "nl": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_NL_NL,
          ...TRANSLATIONS_NL_NL,
        }
      },
      "nl-NL": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_NL_NL,
          ...TRANSLATIONS_NL_NL,
        }
      },
      "en-NL": {
        translation: {
          ...TRANSLATIONS_EN_NL,
        }
      },
      "nb-NO": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_NB_NO,
          ...TRANSLATIONS_NB_NO,
        }
      },
      "nb": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_NB_NO,
          ...TRANSLATIONS_NB_NO,
        }
      },
      "en-NO": {
        translation: {
          ...TRANSLATIONS_EN_NO,
        }
      },
      "sv-SE": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_SV_SE,
          ...TRANSLATIONS_SV_SE,
        }
      },
      "sv": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_SV_SE,
          ...TRANSLATIONS_SV_SE,
        }
      },
      "en-SE": {
        translation: {
          ...TRANSLATIONS_EN_SE,
        }
      },
      "fr-LU": {
        translation: {
          ...TRANSLATIONS_FR_LU,
        }
      },
      "en-LU": {
        translation: {
          ...TRANSLATIONS_EN_LU,
        }
      },
      "fr": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_FR_FR,
          ...TRANSLATIONS_FR_FR,
        }
      },
      "fr-FR": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_FR_FR,
          ...TRANSLATIONS_FR_FR,
        }
      },
      "en-FR": {
        translation: {
          ...TRANSLATIONS_EN_FR,
        }
      },
      "cs": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_CS_CZ,
          ...TRANSLATIONS_CS_CZ,
        }
      },
      "cs-CZ": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_CS_CZ,
          ...TRANSLATIONS_CS_CZ,
        }
      },
      "en-CZ": {
        translation: {
          ...TRANSLATIONS_EN_CZ,
        }
      },
      "it": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_IT_IT,
          ...TRANSLATIONS_IT_IT,
        }
      },
      "it-IT": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_IT_IT,
          ...TRANSLATIONS_IT_IT,
        }
      },
      "en-IT": {
        translation: {
          ...TRANSLATIONS_EN_IT,
        }
      },
      "pl": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_PL_PL,
          ...TRANSLATIONS_PL_PL,
        }
      },
      "pl-PL": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_PL_PL,
          ...TRANSLATIONS_PL_PL,
        }
      },
      "en-PL": {
        translation: {
          ...TRANSLATIONS_EN_PL,
        }
      },
      "sk": {
        translation: {
          ...TRANSLATIONS_SK_SK,
        }
      },
      "sk-SK": {
        translation: {
          ...TRANSLATIONS_SK_SK,
        }
      },
      "en-SK": {
        translation: {
          ...TRANSLATIONS_EN_SK,
        }
      },
      "en-CN": {
        translation: {
          ...TRANSLATIONS_EN_CN,
        }
      },
      "en-JP": {
        translation: {
          ...TRANSLATIONS_EN_JP,
        }
      },
      "ja": {
        translation: {
          ...TRANSLATIONS_JA_JP,
        }
      },
      "ja-JP": {
        translation: {
          ...TRANSLATIONS_JA_JP,
        }
      },
      "zh-CN": {
        translation: {
          ...TRANSLATIONS_ZH_CN,
        }
      },
      "en-US": {
        translation: {
          ...TRANSLATIONS_EN_US,
        }
      },
      "es-US": {
        translation: {
          ...TRANSLATIONS_ES_US,
        }
      },
      "en-LT": {
        translation: {
          ...TRANSLATIONS_EN_LT,
        }
      },
      "lt-LT": {
        translation: {
          ...TRANSLATIONS_LT_LT,
        }
      },
      "en-LV": {
        translation: {
          ...TRANSLATIONS_EN_LV,
        }
      },
      "lv-LV": {
        translation: {
          ...TRANSLATIONS_LV_LV,
        }
      },
      "en-EE": {
        translation: {
          ...TRANSLATIONS_EN_EE,
        }
      },
      "et-EE": {
        translation: {
          ...TRANSLATIONS_ET_EE,
        }
      },
      "en-PT": {
        translation: {
          ...TRANSLATIONS_EN_PT,
        }
      },
      "pt-PT": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_PT_PT,
          ...TRANSLATIONS_PT_PT,
        }
      },
      "pt": {
        translation: {
          ...MAB_VHC_TRANSLATIONS_PT_PT,
          ...TRANSLATIONS_PT_PT,
        }
      },
      "hr-HR": {
        translation: MAB_VHC_TRANSLATIONS_HR_HR
      },
      "hr": {
        translation: MAB_VHC_TRANSLATIONS_HR_HR
      },
      "hu-HU": {
        translation: MAB_VHC_TRANSLATIONS_HU_HU
      },
      "hu": {
        translation: MAB_VHC_TRANSLATIONS_HU_HU
      },
      "ro-RO": {
        translation: MAB_VHC_TRANSLATIONS_RO_RO
      },
      "ro": {
        translation: MAB_VHC_TRANSLATIONS_RO_RO
      },
      "tr-TR": {
        translation: MAB_VHC_TRANSLATIONS_TR_TR
      },
      "tr": {
        translation: MAB_VHC_TRANSLATIONS_TR_TR
      },
    },
    parseMissingKeyHandler: function (key: string) {
      let str = key as keyof typeof TRANSLATIONS_EN;
      let ret = TRANSLATIONS_EN[str];

      if (!ret){
        let vhcStr = key as keyof typeof MAB_VHC_TRANSLATIONS_EN;
        ret = MAB_VHC_TRANSLATIONS_EN[vhcStr];
      }

      if (!ret) ret = key;
      return ret;
    },
  });
