import { useTranslation } from "react-i18next";
import RDAlert from "../../RDComponents/RDAlert";
import { useProceedNegativeCreditBalance } from "../../../hooks/useProceedNegativeCreditBalance";
import { useState } from "react";
import CustomLoader from "../../loading/CustomLoader";
import { useAppDispatch } from "../../../redux/hooks";
import { logoutAsync, selectAccount } from "../../../redux/account";
import { useSelector } from "react-redux";
import { selectCountryCode } from "../../../redux/auth";
import { Constants } from "../../../utils/helpers/Constants";
import RDCommonErrorModal from "@components/RDComponents/RDCommonErrorModal/RDCommonErrorModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const NegativeCreditBalanceModal = ({ isOpen, onClose }: Props) => {
  const { t } = useTranslation();
  const dispatchReduxToolkit = useAppDispatch();
  const { proceedNegativeCreditBalance } = useProceedNegativeCreditBalance();
  const countryCode = useSelector(selectCountryCode);
  const [isLoading, setLoading] = useState(false);
  const [isShowErrorModal, setShowErrorModal] = useState(false);
  const account = useSelector(selectAccount);

  const onRefillCreditBalance = async () => {
    try {
      setLoading(true);

      await proceedNegativeCreditBalance(
        () => onClose(),
        () => {
          setLoading(false);
          setShowErrorModal(true);
        }
      );
    } catch {
      setShowErrorModal(true);
    } finally {
      setLoading(false);
    }
  };

  const onLogout = async () => {
    await dispatchReduxToolkit(logoutAsync());
  };

  return (
    <>
      {countryCode.trim().toLowerCase() === "us" ||
      account?.userRole?.id === Constants.USER_ROLE_INDIVIDUAL_ID ? (
        <RDAlert
          isOpen={isOpen}
          type="warning"
          messages={t(
            "NegativeCreditBalanceModal_CDMUS_YourCreditBalanceIsCurrentlyNegative"
          )}
          onAccept={onLogout}
          acceptButtonText={t("AccountButton_LogoutButton")}
        />
      ) : (
        <RDAlert
          isOpen={isOpen}
          type="warning"
          messages={t(
            "NegativeCreditBalanceModal_CDM3_YourCreditBalanceIsNegative"
          )}
          onAccept={onRefillCreditBalance}
          acceptButtonText={t("ContinueButton")}
          onCancel={onLogout}
          cancelButtonText={t("AccountButton_LogoutButton")}
        />
      )}
      {isShowErrorModal ? (
        <RDCommonErrorModal onClose={() => setShowErrorModal(false)} />
      ) : (
        <></>
      )}
      {isLoading ? <CustomLoader enable /> : <></>}
    </>
  );
};

export default NegativeCreditBalanceModal;
