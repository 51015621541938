export const MAB_VHC_TRANSLATIONS_RO_RO = {
	MAB_VHC_ContactUs_Headline: "Contactaţi-ne",
	MAB_VHC_ContactUs_HowCanWeHelp: "Cum te putem ajuta?",
	MAB_VHC_ContactUs_Email_Headline: "Trimite un e-mail!",
	MAB_VHC_ContactUs_Email_Description: "Trimite-ne mesajul tău completând formularul de mai jos. Unul dintre consilierii noștri va fi bucuros să vă contacteze.",
	MAB_VHC_ContactUs_Email_InputName: "Nume*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Numele este obligatoriu.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Numele depășește limita permisă de 100 de caractere.",
	MAB_VHC_ContactUs_Email_InputEmail: "Adresa de e-mail*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "Este necesară adresa de e-mail.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "Adresa de e-mail depășește limita permisă de 100 de caractere.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Format de e-mail nevalid.",
	MAB_VHC_ContactUs_Email_InputCompany: "Informații despre companie*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Sunt necesare informații despre companie.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Informațiile despre companie depășesc limita permisă de 100 de caractere.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adresa*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Adresa este obligatorie.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Adresa depășește limita permisă de 250 de caractere.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Cod poștal*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Codul poștal este necesar.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Codul poștal depășește limita permisă de 25 de caractere.",
	MAB_VHC_ContactUs_Email_InputCity: "Oraş*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Orașul este necesar.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "Orașul depășește limita permisă de 100 de caractere.",
	MAB_VHC_ContactUs_Email_InputCountry: "Ţară*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Țara este obligatorie.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Țara depășește limita permisă de 100 de caractere.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Certificat / Factura nr",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Certificat/Factura nr. este necesară.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Certificat/Factura nr. depășește limita permisă de 50 de caractere.",
	MAB_VHC_ContactUs_Email_InputReason: "Motivul pentru care ne-ați contacta*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Scurtă descriere*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Este necesară o scurtă descriere.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Scurta descriere depășește limita permisă de 100 de caractere.",
	MAB_VHC_ContactUs_Email_InputDescription: "Descriere*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Este necesară descrierea.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Descrierea depășește limita permisă de 1000 de caractere.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Întrebare*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Este necesară întrebarea.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Întrebarea depășește limita permisă de 100 de caractere.",
	MAB_VHC_ContactUs_Email_Urgency: "Urgenţă",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Nu pot continua să lucrez.",
	MAB_VHC_ContactUs_Email_DataProtection: "Notă privind protecția datelor*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Am citit <a href='{{data_protection_link}}' target='_blank'>Politica de confidențialitate.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Verificare de securitate",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Răspunsul text captcha pe care l-ați introdus este incorect.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Vă rugăm să introduceți caracterele corecte pe care le vedeți în caseta de mai sus.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Vă mulțumim că ne-ați contactat! Mesajul dvs. a fost trimis și vă vom contacta în curând.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Nu am putut trimite e-mailul din cauza unei probleme. Vă rugăm să vă asigurați că detaliile sunt corecte și să încercați din nou.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incident",
	MAB_VHC_ContactUs_Reasons_Bug: "Bug",
	MAB_VHC_ContactUs_Reasons_Question: "Întrebare",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Feedback și solicitare de caracteristici",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Ai probleme? Sau ceva te împiedică să continui să lucrezi? Vă rugăm să nu ezitați să contactați.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Etapa procesului efectuată de incident*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Identificarea vehiculului",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Acces la serviciu",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Pornirea serviciului",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Citirea datelor vehiculului",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Checkout și facturare",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Previzualizarea documentului",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Livrarea documentelor",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Altele",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Ai găsit o eroare? Vă rugăm să nu ezitați să o împărtășiți cu noi.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Etapa procesului efectuată de incident*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Aveți o întrebare legată de serviciul nostru? Întrebarea dvs. nu a fost acoperită de <a href='{{faq_link}}' target='_blank'>Întrebări frecvente</a>? Solicitați sprijinul nostru.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Subiect*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Conținutul serviciului",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Acoperire vehicul",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Acoperire pe țară",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Procesul de checkout",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Prețuri",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware/software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Altele",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Îți lipsește o anumită caracteristică? Sau doriți să oferiți feedback direct? Simțiți-vă liber să contactați.",
	MAB_VHC_SubmitButton: "Trimiteți",
	MAB_VHC_CloseButton: "Inchideti",
};
