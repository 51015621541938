export const MAB_VHC_TRANSLATIONS_CS_CZ = {
	MAB_VHC_ContactUs_Headline: "Kontaktujte nás",
	MAB_VHC_ContactUs_HowCanWeHelp: "Jak vám můžeme pomoci?",
	MAB_VHC_ContactUs_Email_Headline: "Pošlete e-mail!",
	MAB_VHC_ContactUs_Email_Description: "Pošlete nám svou zprávu vyplněním formuláře níže. Jeden z našich poradců se s vámi rád spojí.",
	MAB_VHC_ContactUs_Email_InputName: "Jméno*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Jméno je povinné.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Název překračuje povolený limit 100 znaků.",
	MAB_VHC_ContactUs_Email_InputEmail: "E-mailová adresa*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "E-mailová adresa je povinná.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "E-mailová adresa překračuje povolený limit 100 znaků.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Neplatný formát e-mailu.",
	MAB_VHC_ContactUs_Email_InputCompany: "Informace o společnosti*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Informace o společnosti jsou povinné.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Informace o společnosti přesahují povolený limit 100 znaků.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adresa*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Adresa je povinná.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Adresa přesahuje povolený limit 250 znaků.",
	MAB_VHC_ContactUs_Email_InputZipCode: "PSČ*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Je vyžadováno PSČ.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "PSČ překračuje povolený limit 25 znaků.",
	MAB_VHC_ContactUs_Email_InputCity: "Město*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Město je povinné.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "Město překračuje povolený limit 100 znaků.",
	MAB_VHC_ContactUs_Email_InputCountry: "Země*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Země je povinná.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Země překračuje povolený limit 100 znaků.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Certifikát / číslo faktury",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Certifikát / Faktura č. je vyžadováno.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Certifikát / Faktura č. překračuje povolený limit 50 znaků.",
	MAB_VHC_ContactUs_Email_InputReason: "Důvod, proč nás kontaktovat*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Krátký popis*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Je vyžadován krátký popis.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Krátký popis překračuje povolený limit 100 znaků.",
	MAB_VHC_ContactUs_Email_InputDescription: "Popis*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Popis je povinný.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Popis překračuje povolený limit 1000 znaků.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Otázka*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Otázka je povinná.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Otázka překračuje povolený limit 100 znaků.",
	MAB_VHC_ContactUs_Email_Urgency: "Naléhavost",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Nemohu pokračovat v práci.",
	MAB_VHC_ContactUs_Email_DataProtection: "Upozornění na ochranu údajů*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Přečetl jsem si <a href='{{data_protection_link}}' target='_blank'>Zásady ochrany soukromí.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Bezpečnostní kontrola",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Zadaná textová odpověď captcha je nesprávná.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Zadejte prosím správné znaky, které vidíte v poli výše.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Děkujeme, že jste nás kontaktovali! Vaše zpráva byla odeslána a my se vám brzy ozveme.",
	MAB_VHC_ContactUs_Submit_Error_Message: "E-mail se nepodařilo odeslat kvůli problému. Ujistěte se, že jsou údaje správné, a zkuste to znovu.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incident",
	MAB_VHC_ContactUs_Reasons_Bug: "Bug",
	MAB_VHC_ContactUs_Reasons_Question: "Otázka",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Zpětná vazba a žádost o funkce",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "máš potíže? Nebo vám něco brání v pokračování v práci? Neváhejte nás kontaktovat.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Krok procesu ovlivněný incidentem*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Identifikace vozidla",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Přístup ke službě",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Spuštění služby",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Čtení údajů o vozidle",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Pokladna a fakturace",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Náhled dokumentu",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Doručování dokumentů",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Ostatní",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Našli jste chybu? Neváhejte se o to s námi podělit.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Krok procesu ovlivněný incidentem*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Máte dotaz ohledně naší služby? Vaše otázka nebyla pokryta našimi <a href='{{faq_link}}' target='_blank'>častými dotazy</a>? Zeptejte se naší podpory.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Téma*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Obsah služby",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Pokrytí vozidla",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Pokrytí země",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Proces pokladny",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Ceny",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardware / software",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Ostatní",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Chybí vám určitá funkce? Nebo chcete poskytnout přímou zpětnou vazbu? Klidně se ozvěte.",
	MAB_VHC_SubmitButton: "Předložit",
	MAB_VHC_CloseButton: "Blízko",
};
