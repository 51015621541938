import { createAsyncThunk } from "@reduxjs/toolkit";
import ResponseResultBase from "../models/ResponseResultBase";
import { ApplicationModel } from "../models/NewRequest/Application.model";
import { AppDispatch, RootState } from "../redux/store";
import axios, { AxiosError } from "axios";
import http from "./HttpService";
import { VciFavouriteModel } from "../models/vci/vciFavourite.model";
import { TeamFavouriteModel } from "../models/contactPerson/teamFavourite.model";
import {
  FoundVehicleModel,
  SermiCertConfig,
  VehicleWithKType,
} from "../models/vehicel/vehicel.model";
import {
  GetVehicleInformationBySearchMethodRequestModel as GetVehicleInformationBySearchMethodRequestModel,
  GetVehicleListByKTypeModel,
} from "../models/NewRequest/GetVehicleInformationBySearchMethodRequest.model";
import { TicketCategory } from "../models/NewRequest/TicketCategory.model";
import { CountrySearchMethodModel } from "../models/NewRequest/CountrySearchMethod.model";
import { SearchFunction } from "../models/enums/searchFunction";
import { VehicleCoverageModel } from "../models/NewRequest/VehicleCoverageModel";
import { GetVehicleCoverageRequestModel } from "../models/NewRequest/GetVehicleCoverageRequestModel";

export const searchMethodsByCountryAsync = createAsyncThunk<
  ResponseResultBase<CountrySearchMethodModel> | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("general/searchMethodsByCountry", async (_, thunkApi) => {
  try {
    const response = await http.get<
      ResponseResultBase<CountrySearchMethodModel>
    >(`/api/v1/general/searchMethodsByCountry`);
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getTicketCategoriesAsync = createAsyncThunk<
  ResponseResultBase<TicketCategory> | null,
  void,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("support/getTicketCategories", async (_, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<TicketCategory>>(
      `/api/v1/support/getTicketCategories`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getVehicleInformationMappingBySearchMethodAsync = createAsyncThunk<
  FoundVehicleModel | null,
  GetVehicleInformationBySearchMethodRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "vehicleInformation/getVehicleInformationMappingBySearchMethod",
  async (
    request: GetVehicleInformationBySearchMethodRequestModel,
    thunkApi
  ) => {
    try {
      const response = await http.get<FoundVehicleModel>(
        `/api/v1/VehicleInformation/getVehicelInformationMappingBySearchMethod?registrationNumber=${
          request.searchValue
        }&countryCode=${request.countryCode.toLowerCase()}&searchMethodId=${
          request.searchMethodId
        }`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getUSVehicleInfoBySearchMethodAsync = createAsyncThunk<
  FoundVehicleModel | null,
  GetVehicleInformationBySearchMethodRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "vehicleInformation/getUSVehicleInfoBySearchMethod",
  async (
    request: GetVehicleInformationBySearchMethodRequestModel,
    thunkApi
  ) => {
    try {
      const response = await http.get<FoundVehicleModel>(
        `/api/v1/VehicleInformation/getUSVehicleInfoBySearchMethod?registrationNumber=${
          request.searchValue
        }&countryCode=${request.countryCode.toLowerCase()}&searchMethodId=${
          request.searchMethodId
        }&stateCode=${request.state}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getVehicleInfoBySearchMethodAsync = createAsyncThunk<
  FoundVehicleModel | null,
  GetVehicleInformationBySearchMethodRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "vehicleInformation/getVehicleInfoBySearchMethod",
  async (
    request: GetVehicleInformationBySearchMethodRequestModel,
    thunkApi
  ) => {
    try {
      const response = await http.get<FoundVehicleModel>(
        `/api/v1/VehicleInformation/getVehicleInfoBySearchMethod?registrationNumber=${
          request.searchValue
        }&countryCode=${request.countryCode.toLowerCase()}&searchMethodId=${
          request.searchMethodId
        }&stateCode=${request.state}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getTechnicianFavouriteAsync = createAsyncThunk<
  ResponseResultBase<TeamFavouriteModel> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "contactpersons/GetContactPersonFavourite",
  async (ciamId: string, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<TeamFavouriteModel>>(
        `/api/v1/contactpersons/GetContactPersonFavourite?ciamId=${ciamId}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getHardwareFavouriteAsync = createAsyncThunk<
  ResponseResultBase<VciFavouriteModel> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("vci/GetVciFavourite", async (ciamId: string, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<VciFavouriteModel>>(
      `/api/v1/vci/GetVciFavourite?ciamId=${ciamId}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export const getApplicationsByCountryAsync = createAsyncThunk<
  ResponseResultBase<ApplicationModel[]> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>("vci/GetApplicationsByCountry", async (countryCode: string, thunkApi) => {
  try {
    const response = await http.get<ResponseResultBase<ApplicationModel[]>>(
      `/api/v1/subsystem/GetApplicationsByCountry?CountryCode=${countryCode}`
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return thunkApi.rejectWithValue(error);
    }
  }
  return null;
});

export interface GetVehicleSelectionsAsyncInput {
  searchFunction: SearchFunction;
  make?: string;
  model?: string;
  engine?: string;
  year?: string;
  countryCode: string;
}

export const getVehicleSelectionsAsync = createAsyncThunk<
  string[] | null,
  GetVehicleSelectionsAsyncInput,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "VehicleInformation/GetVehicleSelections",
  async (
    { searchFunction, make, model, engine, year, countryCode },
    thunkApi
  ) => {
    try {
      const response = await http.get<string[]>(
        `/api/v1/VehicleInformation/GetVehicleSelections?searchFunction=${searchFunction}&Make=${make}&Model=${model}&Engine=${engine}&Year=${year}&countryCode=${countryCode}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

///
export const getVehicleCoverageAsync = createAsyncThunk<
  VehicleCoverageModel[] | null,
  GetVehicleCoverageRequestModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "VehicleInformation/GetVehicleSelections",
  async (model: GetVehicleCoverageRequestModel, thunkApi) => {
    try {
      const response = await http.get<VehicleCoverageModel[]>(
        `/api/v1/vehiclehealthcerts/GetVehicleCoverage?carMake=${model.carMake}&carModel=${model.carModel}&carEngine=${model.carEngine}&carYear=${model.carYear}`
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getVehicleListByKTypeAsync = createAsyncThunk<
  ResponseResultBase<VehicleWithKType[]> | null,
  GetVehicleListByKTypeModel,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "vehicleInformation/getVehicleListByKType",
  async (request: GetVehicleListByKTypeModel, thunkApi) => {
    try {
      const response = await http.get<ResponseResultBase<VehicleWithKType[]>>(
        "/api/v1/support/GetCarsByKtypOrRbKey",
        {
          params: {
            KtypeOrRbKey: request.kTypeOrRbKey,
          },
        }
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);

export const getSermiCertConfigAsync = createAsyncThunk<
  ResponseResultBase<SermiCertConfig> | null,
  string,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectValue: AxiosError;
  }
>(
  "vehicleInformation/getSermiCertConfigAsync",
  async (language: string, thunkApi) => {
    try {
      const response = await http.get("/api/v1/support/GetSermiInfo", {
        params: {
          Language: language,
        },
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return thunkApi.rejectWithValue(error);
      }
    }
    return null;
  }
);
