import LoadableComponent from "../../components/loading/LoadableComponent";
import { RouteConfigure } from "../../models/Route";
import { Constants } from "../../utils/helpers/Constants";

/**
 * DEFAULT ROUTES (USED FOR ALL CDM VERSIONS)
 */
export const LOGIN_REDIRECT_ROUTE: RouteConfigure = {
  path: "/login-redirect",
  name: "Login Redirect",
  title: "Login Redirect",
  crumb: "Login Redirect",
  icon: "",
  exact: true,
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/LoginRedirect")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "HomeLayoutCDM3_Login_ButtonLink",
    },
  ],
};

export const LOGOUT_ROUTE: RouteConfigure = {
  path: "/logout",
  name: "Logout",
  title: "Logout",
  crumb: "Logout",
  icon: "",
  exact: true,
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/Logout")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "AccountButton_LogoutButton",
    },
  ],
};

export const NEWS_ROUTE: RouteConfigure = {
  path: "/news",
  name: "News",
  title: "News",
  crumb: "News_Headline",
  icon: "News",
  exact: true,
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/New")),
};

export const SETTINGS_MY_PROFILE_ROUTE: RouteConfigure = {
  path: "/my-profile",
  name: "Settings",
  title: "Settings",
  crumb: "SettingsHeader_Headline",
  icon: "Settings",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(() => import("../../pages/SettingsMyProfile")),
};

export const SETTINGS_TEAM_ROUTE: RouteConfigure = {
  path: "/my-team",
  name: "Settings",
  title: "Settings",
  crumb: "SettingsHeader_Headline",
  icon: "Settings",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(() => import("../../pages/SettingsTeam")),
};

export const SETTINGS_MY_HARDWARE_ROUTE: RouteConfigure = {
  path: "/my-hardware",
  name: "Settings",
  title: "Settings",
  crumb: "SettingsHeader_Headline",
  icon: "Settings",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(() => import("../../pages/SettingsMyHardware")),
};

export const SETTINGS_MY_BILLING_ROUTE: RouteConfigure = {
  path: "/my-billing",
  name: "Settings",
  title: "Settings",
  crumb: "SettingsHeader_Headline",
  icon: "Settings",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(() => import("../../pages/SettingsMyBilling")),
};

export const SETTINGS_UPDATE_PERSONAL_INFO_ROUTE: RouteConfigure = {
  path: "/update-personal-info",
  name: "Settings",
  title: "Settings",
  crumb: "SettingsHeader_Headline",
  icon: "Settings",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(
    () => import("../../pages/SettingsUpdatePersonalInfo")
  ),
};

export const SHOPPING_CART_ROUTE: RouteConfigure = {
  path: "/shopping-cart",
  name: "Shopping Cart",
  title: "Shopping Cart",
  crumb: "MenuHeaderPopup_ShoppingCart",
  icon: "Shopping Cart",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(() => import("../../pages/ShoppingCart")),
};

export const CHECK_OUT_ROUTE: RouteConfigure = {
  path: "/checkout:redirecturl?",
  name: "Check Out",
  title: "Check Out",
  crumb: "BreadScrumb_CheckOut",
  icon: "Check Out",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(() => import("../../pages/CheckOut")),
};

export const MY_TICKETS_ROUTE: RouteConfigure = {
  path: "/my-tickets",
  name: "My Tickets",
  title: "My Tickets",
  crumb: "BreadScrumb_MyTicket",
  icon: "My Tickets",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(() => import("../../pages/MyTickets")),
};

export const SUMMARY_ROUTE: RouteConfigure = {
  path: "/ticket-summary/:id",
  name: "Ticket Summary",
  title: "My Tickets",
  icon: "My Tickets",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(() => import("../../pages/SummaryPage")),
};

export const PRIVACY_POLICY_ROUTE: RouteConfigure = {
  path: "/privacy-policy/:lang?",
  exact: true,
  name: "Privacy Policy",
  crumb: "BreadScrumb_PrivacyPolicy",
  title: "Privacy Policy",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/PrivacyPolicy")),
};

export const TERM_AND_CONDITION_ROUTE: RouteConfigure = {
  path: "/term-condition/:lang?",
  exact: true,
  name: "Term & Condition",
  title: "Term & Condition",
  crumb: "BreadScrumb_TermAndCondition",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/TermAndCondition")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "BreadScrumb_TermAndCondition",
    },
  ],
};

export const STATEMENT_OF_WORK_ROUTE: RouteConfigure = {
  path: "/statement-of-work/:lang?",
  exact: true,
  name: "Statement of Work",
  title: "Statement of Work",
  // crumb: "BreadScrumb_TermAndCondition",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/VHC/StatementOfWork")),
  // breadcrumbs: [
  //   {
  //     label: "BreadScrumb_Home",
  //     path: "/",
  //   },
  //   // {
  //   //   label: "BreadScrumb_TermAndCondition",
  //   // },
  // ],
};

export const DPN_VEHICLE_OWNER_ROUTE: RouteConfigure = {
  path: "/data-protection-notice-vehicle-owner/:lang?",
  exact: true,
  name: "Data Protection Notice Vehicle Owner",
  title: "Data Protection Notice Vehicle Owner",
  // crumb: "BreadScrumb_TermAndCondition",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/VHC/DPNVehicleOwner")),
  // breadcrumbs: [
  //   {
  //     label: "BreadScrumb_Home",
  //     path: "/",
  //   },
  //   // {
  //   //   label: "BreadScrumb_TermAndCondition",
  //   // },
  // ],
};

export const SUB_DOMAIN_REDIRECT_ROUTE: RouteConfigure = {
  path: "/redirect-subdomain",
  exact: true,
  name: "Redirect",
  title: "Redirect",
  crumb: "Redirect",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/SubDomainRedirect")),
};

export const MAIN_SITE_REDIRECT_ROUTE: RouteConfigure = {
  path: "/redirect-mainsite",
  exact: true,
  name: "Redirect",
  title: "Redirect",
  crumb: "Redirect",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/MainSiteRedirect")),
};

export const CLEAR_STATE_ROUTE: RouteConfigure = {
  path: "/clear-state",
  exact: true,
  name: "Clear state",
  title: "Clear state",
  crumb: "Redirect",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/ClearState")),
};

export const EXCEPTION_ROUTE: RouteConfigure = {
  path: "/exception?:type",
  exact: true,
  name: "exception",
  title: "exception",
  crumb: "BreadScrumb_Exception",
  icon: "info-circle",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/Exception")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "BreadScrumb_Exception",
    },
  ],
};

export const SEARCH_RESULTS_ROUTE: RouteConfigure = {
  path: "/search-results",
  exact: true,
  name: "Search Results",
  title: "Search Results",
  crumb: "BreadScrumb_SearchResults",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/SearchResults")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "BreadScrumb_SearchResults",
    },
  ],
};

export const CORPORATION_INFO_ROUTE: RouteConfigure = {
  path: "/corporate-information/:c?",
  exact: true,
  name: "Corporate Information",
  title: "Corporate Information",
  crumb: "FooterAdditionalLinks_Navigation_CorporateInformation",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/Info")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "FooterAdditionalLinks_Navigation_CorporateInformation",
    },
  ],
};

export const LEGAL_NOTICE_ROUTE: RouteConfigure = {
  path: "/legal-notice/:c?",
  exact: true,
  name: "Legal Notice",
  title: "Legal Notice",
  crumb: "FooterAdditionalLinks_Navigation_LegalNotice",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/Info")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "FooterAdditionalLinks_Navigation_LegalNotice",
    },
  ],
};

export const DATA_PROTECTION_NOTICE_ROUTE: RouteConfigure = {
  path: "/data-protection-notice/:c?",
  exact: true,
  name: "Data Protection Notice",
  title: "Data Protection Notice",
  crumb: "FooterAdditionalLinks_Navigation_DataProtectionNotice",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/Info")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "FooterAdditionalLinks_Navigation_DataProtectionNotice",
    },
  ],
};

export const FOSS_LICENSE_ROUTE: RouteConfigure = {
  path: "/oss-mobile",
  exact: true,
  name: "FOSS Licenses",
  title: "FOSS Licenses",
  crumb: "FooterAdditionalLinks_Navigation_FossLicenses",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/FossLicenses")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "FooterAdditionalLinks_Navigation_FossLicenses",
    },
  ],
};

export const ORDER_SUMMARY_ROUTE: RouteConfigure = {
  path: "/order-summary/:id",
  name: "Order Summary",
  title: "My Order",
  crumb: "BreadScrumb_CheckOut",
  icon: "My Orders",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(
    () => import("../../pages/OrderSummaryComponent")
  ),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "BreadScrumb_CheckOut",
    },
  ],
};

export const DASHBOARD_HARDWARE_ROUTE: RouteConfigure = {
  path: "/dashboard/hardware",
  name: "Install Base",
  title: "Install Base",
  crumb: "DashboardCDM3_MainMenu_InstalledBase_Item",
  exact: true,
  isProtected: true,
  permission: "",
  isMapingRequired: true,
  role: [Constants.USER_ROLE_MANAGER_ID],
  component: LoadableComponent(
    () => import("../../pages/DashboardInstallBaseCDM3")
  ),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "DashboardCDM3_MainMenu_InstalledBase_Item",
    },
  ],
};

export const SETTINGS_MY_PROFILE_ACTIVATION_ROUTE: RouteConfigure = {
  path: "/dashboard/editmyprofile/activate",
  name: "Dashboard",
  title: "Dashboard",
  crumb: "Breadcrumb_Dashboard",
  icon: "Dashboard",
  exact: true,
  isProtected: true,
  permission: "",
  component: LoadableComponent(
    () => import("../../pages/SettingsMyProfileActivation")
  ),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "Breadcrumb_Dashboard",
    },
  ],
};

export const SOFTWARE_LICENSE_ROUTE: RouteConfigure = {
  path: "/software-licenses",
  name: "FOSS Licenses",
  title: "FOSS Licenses",
  crumb: "SoftWareLicense_FossLicense_Header",
  exact: true,
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/SoftwareLicense")),
  breadcrumbs: [
    {
      label: "BreadScrumb_Home",
      path: "/",
    },
    {
      label: "SoftWareLicense_FossLicense_Header",
    },
  ],
};

export const TEAM_MEMBER_ACCOUNT_CREATION_ROUTE: RouteConfigure = {
  path: "/dashboard/myteam/employee-creation:redirecturl?",
  name: "employee Creation",
  title: "Employee Creation",
  crumb: "Breadcrumb_EmployeeCreation",
  icon: "Employee Creation",
  exact: true,
  isProtected: false,
  permission: "",
  component: LoadableComponent(
    () => import("../../pages/TeamMemberAccountCreation")
  ),
};

export const TEAM_MEMBER_ACCOUNT_MODIFICATION_ROUTE: RouteConfigure = {
  path: "/dashboard/myteam/employee-modification/:employeeId?",
  name: "Employee Modification",
  title: "Employee Modification",
  crumb: "Breadcrumb_EmployeeModification",
  icon: "Employee Modification",
  exact: true,
  isProtected: false,
  permission: "",
  component: LoadableComponent(
    () => import("../../pages/TeamMemberAccountModification")
  ),
};

export const REGISTER_PERSONAL_ACCOUNT_ROUTE: RouteConfigure = {
  path: "/RegisterPersonalAccount/:paramCountryCode/:paramLanguageCode/:guid",
  exact: true,
  name: "Register Account",
  title: "Register Account | Bosch Remote Diagnostics",
  crumb: "Breadcrumb_RegisterAccount",
  isProtected: false,
  permission: "",
  component: LoadableComponent(
    () => import("../../pages/RegisterPersonalAccount")
  ),
};

export const WINDOW_MEETING_ROUTE: RouteConfigure = {
  path: "/meeting/:meeting?",
  name: "Video Session",
  title: "Video Session",
  crumb: "",
  exact: true,
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/WindowMeeting")),
};

export const EDIT_PROFILE_ROUTE: RouteConfigure = {
  path: "editprofilecallback",
  name: "Edit profile",
  title: "EditButton",
  crumb: "EditButton",
  exact: true,
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/EditProfileCallback")),
};

export const VHC_CONTACT_FORM_ROUTE: RouteConfigure = {
  path: "/vhc/contact/:lang?",
  exact: true,
  name: "VHC Contact",
  title: "VHC Contact",
  // crumb: "FooterAdditionalLinks_Navigation_DataProtectionNotice",
  isProtected: false,
  permission: "",
  component: LoadableComponent(() => import("../../pages/VHC/ContactUs")),
  // breadcrumbs: [
  //   {
  //     label: "BreadScrumb_Home",
  //     path: "/",
  //   },
  //   {
  //     label: "FooterAdditionalLinks_Navigation_DataProtectionNotice",
  //   },
  // ],
};
