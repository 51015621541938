import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { HOME_CDM_3_ROUTE_PL_PATH } from "../constants/routes/cdm3v0Routes.constants";
import { useRouting } from "../hooks/useRouting";
import useCaseInsensitiveSearchParams from "@hooks/useCaseInsensitiveSearchParams";

interface IPublicRouteProps extends RouteProps {
  countryCode: string;
}

const PublicRoute: React.FC<IPublicRouteProps> = ({
  component: Component,
  countryCode,
  ...rest
}) => {
  const { getParam } = useCaseInsensitiveSearchParams();
  const { redirectTo401Page, redirectToRoute } = useRouting();
  const isSelectedRegion = !!countryCode && countryCode !== "";
  const isIgnoreGEO = getParam("isignoregeo") === "1";

  return (
    <Route
      {...rest}
      render={(insideProps) => {
        const urlParams = new URLSearchParams(window.location.search);
        const country = urlParams.get("c");
        const lang = urlParams.get("l");
        const isGeo =
          window.location.search !== "" ? urlParams.get("isGeo") : "";

        const utmSource = urlParams.get("utm_source");
        const utmMedium = urlParams.get("utm_medium");
        const utmCampaign = urlParams.get("utm_campaign");

        if (!!country && !!lang && isGeo !== "1") {
          if (
            country.trim().toLowerCase() === "pl" &&
            !!utmCampaign &&
            !!utmSource &&
            !!utmMedium
          )
            return redirectToRoute(HOME_CDM_3_ROUTE_PL_PATH, {
              from: insideProps.location,
            });

          return redirectToRoute("/", { from: insideProps.location });
        }

        if (
          window.location.href
            .toLowerCase()
            .includes("/registerpersonalaccount")
        ) {
          return Component ? (
            <Component {...insideProps} />
          ) : (
            redirectTo401Page(insideProps.location)
          );
        }

        if (
          !(
            window.location.href.includes("/regions") ||
            window.location.href.includes("/redirect-subdomain") ||
            window.location.href.includes("/exception") ||
            window.location.href.includes("/clear-state") ||
            window.location.href.includes("/privacy-policy/") ||
            window.location.href.includes("/term-condition") ||
            window.location.href.includes("/corporate-information/") ||
            window.location.href.includes("/legal-notice/") ||
            window.location.href.includes("/oss-mobile") ||
            window.location.href.includes("/data-protection-notice/") ||
            window.location.href.includes("/meeting/")
          )
        ) {
          if (!isSelectedRegion && !isIgnoreGEO)
            return redirectToRoute("/regions", { from: insideProps.location });
        }

        return Component ? (
          <Component {...insideProps} />
        ) : (
          redirectTo401Page(insideProps.location)
        );
      }}
    />
  );
};

export default PublicRoute;
