export const MAB_VHC_TRANSLATIONS_HU_HU = {
	MAB_VHC_ContactUs_Headline: "Vegye fel velünk a kapcsolatot",
	MAB_VHC_ContactUs_HowCanWeHelp: "Hogyan segíthetünk?",
	MAB_VHC_ContactUs_Email_Headline: "Küldj e-mailt!",
	MAB_VHC_ContactUs_Email_Description: "Küldje el nekünk üzenetét az alábbi űrlap kitöltésével. Tanácsadónk szívesen felveszi Önnel a kapcsolatot.",
	MAB_VHC_ContactUs_Email_InputName: "Név*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "A név megadása kötelező.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "A név meghaladja a megengedett 100 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputEmail: "E-mail cím*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "E-mail cím megadása kötelező.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "Az e-mail cím meghaladja a megengedett 100 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Érvénytelen e-mail formátum.",
	MAB_VHC_ContactUs_Email_InputCompany: "Cégadatok*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Cégadatok szükségesek.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "A cégadatok túllépik a megengedett 100 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputAddress: "Cím*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Cím megadása kötelező.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "A cím meghaladja a megengedett 250 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Irányítószám*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Az irányítószám megadása kötelező.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Az irányítószám meghaladja a megengedett 25 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputCity: "Város*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Város megadása kötelező.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "A városnév meghaladja a megengedett 100 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputCountry: "Ország*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Az ország megadása kötelező.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Az országnév meghaladja a megengedett 100 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Igazolás / számla sz",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Igazolás / Számla sz. szükséges.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Igazolás / Számla sz. meghaladja a megengedett 50 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputReason: "A kapcsolatfelvétel oka*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Rövid leírás*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Rövid leírás szükséges.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "A rövid leírás meghaladja a megengedett 100 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputDescription: "Leírás*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Leírás szükséges.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "A leírás meghaladja a megengedett 1000 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Kérdés*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Kérdés kötelező.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "A kérdés meghaladja a megengedett 100 karakteres korlátot.",
	MAB_VHC_ContactUs_Email_Urgency: "Sürgősség",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Nem tudok tovább dolgozni.",
	MAB_VHC_ContactUs_Email_DataProtection: "Adatvédelmi közlemény*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Elolvastam az <a href='{{data_protection_link}}' target='_blank'>Adatvédelmi irányelveket*</a>.",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Biztonsági ellenőrzés",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "A beírt captcha szöveges válasz helytelen.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Kérjük, írja be a megfelelő karaktereket, amelyeket a fenti mezőben lát.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Köszönjük, hogy felvette velünk a kapcsolatot! Üzenetét elküldtük, hamarosan felvesszük Önnel a kapcsolatot.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Hiba miatt nem tudtuk elküldeni az e-mailt. Kérjük, ellenőrizze az adatok helyességét, és próbálja újra.",
	MAB_VHC_ContactUs_Reasons_Incident: "Esemény",
	MAB_VHC_ContactUs_Reasons_Bug: "Szoftverhiba",
	MAB_VHC_ContactUs_Reasons_Question: "Kérdés",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Visszajelzés és funkciókérés",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Problémába ütközött? Vagy valami akadályozza a munka folytatását? Kérjük, ne habozzon felvenni velünk a kapcsolatot.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Az incidens által végrehajtott folyamatlépés*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "A jármű azonosítása",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Hozzáférés a szolgáltatáshoz",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "A szolgáltatás elindítása",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Járműadatok kiolvasása",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Pénztár és számlázás",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Dokumentum előnézete",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Dokumentum kézbesítés",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Mások",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Találtál egy hibát? Kérjük, bátran ossza meg velünk.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Az incidens által végrehajtott folyamatlépés*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Kérdése van szolgáltatásunkkal kapcsolatban? Kérdésére nem vonatkozott a <a href='{{faq_link}}' target='_blank'>GYIK</a>? Kérje támogatásunkat.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Téma*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Szolgáltatás tartalma",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Jármű lefedettség",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Országos lefedettség",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Kijelentkezési folyamat",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Árképzés",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hardver / szoftver",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Egyéb",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Hiányzik egy bizonyos funkció? Vagy közvetlen visszajelzést szeretne adni? Keress bátran!",
	MAB_VHC_SubmitButton: "Beküld",
	MAB_VHC_CloseButton: "Bezár",
};
