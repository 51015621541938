export const MAB_VHC_TRANSLATIONS_SV_SE = {
	MAB_VHC_ContactUs_Headline: "Kontakta oss",
	MAB_VHC_ContactUs_HowCanWeHelp: "Hur kan vi hjälpa dig?",
	MAB_VHC_ContactUs_Email_Headline: "Skicka ett e-postmeddelande!",
	MAB_VHC_ContactUs_Email_Description: "Skicka ditt meddelande till oss genom att fylla i formuläret nedan. En av våra rådgivare kontaktar dig gärna.",
	MAB_VHC_ContactUs_Email_InputName: "Namn*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Namn krävs.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Namnet överskrider den tillåtna gränsen på 100 tecken.",
	MAB_VHC_ContactUs_Email_InputEmail: "E-postadress*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "E-postadress krävs.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "E-postadressen överskrider den tillåtna gränsen på 100 tecken.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Ogiltigt e-postformat.",
	MAB_VHC_ContactUs_Email_InputCompany: "Företagsinformation*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Företagsinformation krävs.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Företagsinformationen överskrider den tillåtna gränsen på 100 tecken.",
	MAB_VHC_ContactUs_Email_InputAddress: "Adress*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Adress krävs.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Adressen överskrider den tillåtna gränsen på 250 tecken.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Postnummer*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Postnummer krävs.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Postnummer överskrider den tillåtna gränsen på 25 tecken.",
	MAB_VHC_ContactUs_Email_InputCity: "Stad*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Stad krävs.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "Stad överskrider den tillåtna gränsen på 100 tecken.",
	MAB_VHC_ContactUs_Email_InputCountry: "Land*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Land krävs.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Land överskrider den tillåtna gränsen på 100 tecken.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Certifikat/faktura nr",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Certifikat / Faktura nr. krävs.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Certifikat / Faktura nr. överskrider den tillåtna gränsen på 50 tecken.",
	MAB_VHC_ContactUs_Email_InputReason: "Anledning till att kontakta oss*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Kort beskrivning*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Kort beskrivning krävs.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Kort beskrivning överskrider den tillåtna gränsen på 100 tecken.",
	MAB_VHC_ContactUs_Email_InputDescription: "Beskrivning*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Beskrivning krävs.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Beskrivningen överskrider den tillåtna gränsen på 1000 tecken.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Fråga*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Fråga krävs.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Frågan överskrider den tillåtna gränsen på 100 tecken.",
	MAB_VHC_ContactUs_Email_Urgency: "Brådskande karaktär",
	MAB_VHC_ContactUs_Email_Urgency_Message: "Jag kan inte fortsätta jobba.",
	MAB_VHC_ContactUs_Email_DataProtection: "Dataskyddsmeddelande*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Jag har läst <a href='{{data_protection_link}}' target='_blank'>sekretesspolicyn.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Säkerhetskontroll",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Captcha-svaret du angav är felaktigt.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Vänligen ange rätt tecken som du ser i rutan ovan.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Tack för att du kontaktar oss! Ditt meddelande har skickats och vi återkommer till dig inom kort.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Vi kunde inte skicka e-postmeddelandet på grund av ett problem. Se till att uppgifterna är korrekta och försök igen.",
	MAB_VHC_ContactUs_Reasons_Incident: "Incident",
	MAB_VHC_ContactUs_Reasons_Bug: "Bugg",
	MAB_VHC_ContactUs_Reasons_Question: "Fråga",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Feedback och funktionsbegäran",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Har du problem? Eller är det något som hindrar dig från att fortsätta arbeta? Tveka inte att höra av dig.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Processsteg som påverkats av incidenten*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Fordonsidentifiering",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Tillgång till tjänsten",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Startar tjänsten",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Avläsning av fordonsdata",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Kassa och fakturering",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Förhandsgranskning av dokument",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Dokumentleverans",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Andra",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Har du hittat en bugg? Dela den gärna med oss.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Processsteg som påverkats av incidenten*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Har du en fråga angående vår tjänst? Din fråga täcktes inte av våra <a href='{{faq_link}}' target='_blank'>vanliga frågor</a>? Fråga vår support.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Ämne*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Tjänstens innehåll",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Fordonstäckning",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Landstäckning",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Kassaprocess",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Prissättning",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Hårdvara/mjukvara",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Andra",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Saknar du en viss funktion? Eller vill du ge direkt feedback? Hör gärna av dig.",
	MAB_VHC_SubmitButton: "Överlämna",
	MAB_VHC_CloseButton: "Nära",
};
