import { DateFormat, DateType } from "../models/dateFormat";

export const formatCDMDateTime = (data: DateFormat) => {
  if (!data.input) return "-";

  const originalDate = new Date(data.input);
  let result = "";
  const dateSeparator = !!data.dateSeparator ? data.dateSeparator : "/";

  const day = ("00" + originalDate.getDate()).slice(-2);
  const month = ("00" + (originalDate.getMonth() + 1)).slice(-2);
  const year = ("0000" + originalDate.getFullYear()).slice(-4);

  const hours = ("00" + originalDate.getHours()).slice(-2);
  const mins = ("00" + originalDate.getMinutes()).slice(-2);
  const secs = ("00" + originalDate.getSeconds()).slice(-2);
  const timePostfix = Number(hours) > 13 ? "PM" : "AM";

  if (!data.dateType || data.dateType == DateType.DMY)
    result += `${day}${dateSeparator}${month}${dateSeparator}${year}`;
  else if (data.dateType === DateType.MDY)
    result += `${month}${dateSeparator}${day}${dateSeparator}${year}`;
  else if (data.dateType === DateType.YMD)
    result += `${year}${dateSeparator}${month}${dateSeparator}${day}`;
  else result += `${year}${dateSeparator}${day}${dateSeparator}${month}`;

  if (!data.timeType) return result;

  if (data.timeType === "hm") result += ` ${hours}:${mins}`;
  else if (data.timeType === "hmp")
    result += ` ${hours}:${mins} ${timePostfix}`;
  else if (data.timeType === "hms") result += ` ${hours}:${mins}:${secs}`;
  else if (data.timeType === "hmsp")
    result += ` ${hours}:${mins}:${secs} ${timePostfix}`;

  return result;
};

export const getBrowserDateFormat = (): DateType => {
  let now = new Date(2013, 11, 31);
  let string = now.toLocaleDateString();

  string = string.replace("31", "dd");
  string = string.replace("12", "mm");
  string = string.replace("2013", "yyyy");
  string = string.replaceAll("/", "-");

  return string as DateType;
};

export const getCurrentYear = (): number => {
  return new Date().getFullYear();
};

export const getCurrentMonth = (): number => {
  return new Date().getMonth() + 1;
};
