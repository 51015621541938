import { FC, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Image } from "semantic-ui-react";
import style from "./MaintenancePage.module.css";
import boschSupergraphic from "../../assets/Bosch-Supergraphic_.png";
import logo from "../../assets/Bosch_DigitalLogo_BlackAnchor_144x96px.png";
import { ReactComponent as MaintenanceImage } from "../../assets/svg/maintenance.svg";

const MaintenancePage: FC<any> = () => {
  const { t } = useTranslation();

  const maintenanceOnlineDate = "17/01/2025 at 08:00AM CET";

  return (
    <Fragment>
      <div className="supergraphic">
        <Image src={boschSupergraphic} fluid></Image>
      </div>

      <div className="grid-responsive-width">
        <div className="header-large-box">
          <Image src={logo} as="a" href="/"></Image>
          <h5 className="landing-page-title">{t("LandingPageCDM3_Title")}</h5>
        </div>

        <div className={`${style.maintenance_message}`}>
          <p className={`${style.info_message}`}>
            {t("MaintenancePage_Message_1")}
            <br></br>
            {t("MaintenancePage_Message_2").replace(
              "[maintenance_online]",
              maintenanceOnlineDate || ""
            )}
          </p>
          <div className={`${style.maintenance_image}`}>
            <MaintenanceImage />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MaintenancePage;
