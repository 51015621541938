import { useSelector } from "react-redux";
import { useAppDispatch } from "../redux/hooks";
import {
  selectCdbId,
  selectCiamId,
  selectSolutionGroups,
} from "../redux/account";
import {
  selectCountryCode,
  selectLanguage,
  selectTimeZoneId,
} from "../redux/auth";
import { selectFormValues } from "../redux/newRequestSlice";
import { useUTCTime } from "./useUTCTime";
import { createQueuedSupportRequest } from "../services/USNewRequestService";
import { Constants } from "../utils/helpers/Constants";

export const useSubmitNewRequestOnUs = () => {
  const dispatch = useAppDispatch();
  const ciamId = useSelector(selectCiamId);
  const countryCode = useSelector(selectCountryCode);
  const language = useSelector(selectLanguage);
  const timeZoneId = useSelector(selectTimeZoneId);
  const formValues = useSelector(selectFormValues);
  const solutionGroups = useSelector(selectSolutionGroups);
  const { getUtcTimeWithAdjustment } = useUTCTime();
  const cdbId = useSelector(selectCdbId);

  const submitNewRequestOnUs = async () => {
    const utcTime = await getUtcTimeWithAdjustment();

    if (!utcTime) return;

    return dispatch(
      createQueuedSupportRequest({
        ciamId,
        customerId: cdbId || 0,
        countryCode,
        language,
        timeZoneId,
        supportRequestTypeId: formValues.serviceTypeSelected,
        subject: `${formValues.brandSelected}, ${formValues.modelSelected}`,
        comments: formValues.comments,
        supportRequestStatusId: Constants.REQUEST_STATUS_ID_QUEUED,
        assignedSolutionGroups: solutionGroups || [],
        createdAtUtc: utcTime.toUTCString(),
        contactPersonId: formValues.technicianSelectedModel?.id || "",
        vciSerialNumber: formValues.hardwareSelectedModel?.serialNumber || "",
        make: formValues.brandSelected,
        model: formValues.modelSelected,
        displacement: Number(formValues.engineSelected),
        year: formValues.yearSelected,
        vin: formValues.vin,
        licensePlate: (formValues.licensePlate || "").trim(),
        engineCode: "",
        vehicleVariant: formValues.variantSelected,
        isTHLTicket: false,
        asapFlag: formValues.isContactAsapRequest,
        ticketCategoryId: 0,
        repairOrderId: formValues.repairOrder || "",
        ...(!formValues?.mileage || formValues.mileage === ""
          ? {}
          : {
              mileAge: Number(formValues.mileage) || 0,
            }),
        vciSerial: formValues.selectedVciSerial || "",
        diagnosticToolSerial: formValues.selectedDiagSerial || "",
      })
    );
  };

  return {
    submitNewRequestOnUs,
  };
};
