export const MAB_VHC_TRANSLATIONS_FI_FI = {
	MAB_VHC_ContactUs_Headline: "Ota yhteyttä",
	MAB_VHC_ContactUs_HowCanWeHelp: "Miten voimme auttaa sinua?",
	MAB_VHC_ContactUs_Email_Headline: "Lähetä sähköpostia!",
	MAB_VHC_ContactUs_Email_Description: "Lähetä meille viesti täyttämällä alla oleva lomake. Otamme mielellämme teihin yhteyttä.",
	MAB_VHC_ContactUs_Email_InputName: "Nimi*",
	MAB_VHC_ContactUs_Email_InputName_Required_Message: "Nimi vaaditaan.",
	MAB_VHC_ContactUs_Email_InputName_MaxLength_Message: "Nimi ylittää sallitun 100 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputEmail: "Sähköpostiosoite*",
	MAB_VHC_ContactUs_Email_InputEmail_Required_Message: "Sähköpostiosoite vaaditaan.",
	MAB_VHC_ContactUs_Email_InputEmail_MaxLength_Message: "Sähköpostiosoite ylittää sallitun 100 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputEmail_Invalid_Message: "Virheellinen sähköpostin muoto.",
	MAB_VHC_ContactUs_Email_InputCompany: "Yrityksen tiedot*",
	MAB_VHC_ContactUs_Email_InputCompany_Required_Message: "Yrityksen tiedot vaaditaan.",
	MAB_VHC_ContactUs_Email_InputCompany_MaxLength_Message: "Yritystiedot ylittävät sallitun 100 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputAddress: "Osoite*",
	MAB_VHC_ContactUs_Email_InputAddress_Required_Message: "Osoite vaaditaan.",
	MAB_VHC_ContactUs_Email_InputAddress_MaxLength_Message: "Osoite ylittää sallitun 250 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputZipCode: "Postinumero*",
	MAB_VHC_ContactUs_Email_InputZipCode_Required_Message: "Postinumero vaaditaan.",
	MAB_VHC_ContactUs_Email_InputZipCode_MaxLength_Message: "Postinumero ylittää sallitun 25 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputCity: "Paikkakunta*",
	MAB_VHC_ContactUs_Email_InputCity_Required_Message: "Paikkakunta vaaditaan.",
	MAB_VHC_ContactUs_Email_InputCity_MaxLength_Message: "Paikkakunta ylittää sallitun 100 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputCountry: "Maa*",
	MAB_VHC_ContactUs_Email_InputCountry_Required_Message: "Maa on pakollinen.",
	MAB_VHC_ContactUs_Email_InputCountry_MaxLength_Message: "Maa ylittää sallitun 100 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputCertNo: "Todistus/lasku nro",
	MAB_VHC_ContactUs_Email_InputCertNo_Required_Message: "Todistus / lasku nro. vaaditaan.",
	MAB_VHC_ContactUs_Email_InputCertNo_MaxLength_Message: "Todistus / lasku nro. ylittää sallitun 50 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputReason: "Yhteydenoton tarkoitus*",
	MAB_VHC_ContactUs_Email_InputShortDescription: "Lyhyt kuvaus*",
	MAB_VHC_ContactUs_Email_InputShortDescription_Required_Message: "Lyhyt kuvaus vaaditaan.",
	MAB_VHC_ContactUs_Email_InputShortDescription_MaxLength_Message: "Lyhyt kuvaus ylittää sallitun 100 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputDescription: "Kuvaus*",
	MAB_VHC_ContactUs_Email_InputDescription_Required_Message: "Kuvaus vaaditaan.",
	MAB_VHC_ContactUs_Email_InputDescription_MaxLength_Message: "Kuvaus ylittää sallitun 1000 merkin rajan.",
	MAB_VHC_ContactUs_Email_InputQuestion: "Kysymys*",
	MAB_VHC_ContactUs_Email_InputQuestion_Required_Message: "Kysymys on pakollinen.",
	MAB_VHC_ContactUs_Email_InputQuestion_MaxLength_Message: "Kysymys ylittää sallitun 100 merkin rajan.",
	MAB_VHC_ContactUs_Email_Urgency: "Kiireellisyys",
	MAB_VHC_ContactUs_Email_Urgency_Message: "En voi jatkaa työskentelyä.",
	MAB_VHC_ContactUs_Email_DataProtection: "Tietosuojailmoitus*",
	MAB_VHC_ContactUs_Email_DataProtection_Description: "Olen lukenut <a href='{{data_protection_link}}' target='_blank'>tietosuojakäytännön.*</a>",
	MAB_VHC_ContactUs_Email_SecurityCheck: "Turvatarkastus",
	MAB_VHC_ContactUs_Email_SecurityCodeInvalid: "Antamasi captcha-tekstivastaus on virheellinen.",
	MAB_VHC_ContactUs_Email_SecurityCodeConfirm: "Syötä oikeat merkit, jotka näet yllä olevassa kentässä.",
	MAB_VHC_ContactUs_Email_SuccessMessage: "Kiitos yhteydenotostasi! Viestisi on lähetetty, ja palaamme asiaan pian.",
	MAB_VHC_ContactUs_Submit_Error_Message: "Emme voineet lähettää sähköpostia ongelman vuoksi. Varmista, että tiedot ovat oikein ja yritä uudelleen.",
	MAB_VHC_ContactUs_Reasons_Incident: "Tapahtuma",
	MAB_VHC_ContactUs_Reasons_Bug: "Bug",
	MAB_VHC_ContactUs_Reasons_Question: "Kysymys",
	MAB_VHC_ContactUs_Reasons_FeedbackFeatureRequest: "Palaute ja ominaisuuspyyntö",
	MAB_VHC_ContactUs_Reasons_Incident_Form_Text: "Onko sinulla ongelmia? Vai estääkö jokin sinua jatkamasta työtäsi? Älä epäröi ottaa yhteyttä.",
	MAB_VHC_ContactUs_Reason_Incident_Topic: "Prosessivaiheeseen vaikuttava virhe*",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleIdentification: "Ajoneuvon tunnistus",
	VHC_CONTACT_INCIDENT_TOPIC_AccessToTheService: "Palveluun pääsy",
	VHC_CONTACT_INCIDENT_TOPIC_StartingTheService: "Palvelun käynnistäminen",
	VHC_CONTACT_INCIDENT_TOPIC_VehicleDataReadout: "Ajoneuvon tietojen lukeminen",
	VHC_CONTACT_INCIDENT_TOPIC_CheckoutAndInvoicing: "Kassa ja laskutus",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentPreview: "Asiakirjan esikatselu",
	VHC_CONTACT_INCIDENT_TOPIC_DocumentDelivery: "Asiakirjan toimitus",
	VHC_CONTACT_INCIDENT_TOPIC_Others: "Muut",
	MAB_VHC_ContactUs_Reasons_Bug_Form_Text: "Oletko löytänyt bugin? Voit vapaasti jakaa sen kanssamme.",
	MAB_VHC_ContactUs_Reason_Bug_Topic: "Prosessivaiheeseen vaikuttava virhe*",
	MAB_VHC_ContactUs_Reasons_Question_Form_Text: "Onko sinulla kysyttävää palvelustamme? Eivätkö <a href='{{faq_link}}' target='_blank'>Usein kysytyt kysymykset</a> käsittele kysymystäsi? Kysy tukeamme.",
	MAB_VHC_ContactUs_Reason_Question_Topic: "Aihe*",
	MAB_VHC_ContactUs_Reason_Question_Topic_ServiceContent: "Palvelun sisältö",
	MAB_VHC_ContactUs_Reason_Question_Topic_VehicleCoverage: "Ajoneuvojen kattavuus",
	MAB_VHC_ContactUs_Reason_Question_Topic_CountryCoverage: "Maakohtainen kattavuus",
	MAB_VHC_ContactUs_Reason_Question_Topic_CheckoutProcess: "Kassaprosessi",
	MAB_VHC_ContactUs_Reason_Question_Topic_Pricing: "Hinnoittelu",
	MAB_VHC_ContactUs_Reason_Question_Topic_HardwareSoftware: "Laitteisto/ohjelmisto",
	MAB_VHC_ContactUs_Reason_Question_Topic_Others: "Muut",
	MAB_VHC_ContactUs_Reasons_Feature_Form_Text: "Puuttuuko sinulta jokin tietty ominaisuus? Tai haluatko antaa suoraa palautetta? Ota rohkeasti yhteyttä.",
	MAB_VHC_SubmitButton: "Lähetä",
	MAB_VHC_CloseButton: "Sulje",
};
