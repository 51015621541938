import { Button, Input, InputProps } from "semantic-ui-react";
import { ReactComponent as Search } from "../../assets/svg/search.svg";
import { ReactComponent as Close } from "../../assets/svg/close.svg";
import Styles from "./RDSearchInput.module.css";
import { ForwardedRef, forwardRef } from "react";
import Boschicon from "../BoschIcon/Boschicon";
import { useTranslation } from "react-i18next";

interface RDSearchInputProps extends InputProps {
  maxLength?: number;
  errorMessage?: string;
  title?: string;
  containerClassName?: string;
}

const RDSearchInput = forwardRef<Input, RDSearchInputProps>(
  (props: InputProps, ref: ForwardedRef<Input>) => {
    const { t } = useTranslation();
    const {
      onClear,
      onSearch,
      errorMessage,
      maxLength,
      title,
      containerClassName,
      ...restrictedProps
    } = props;

    const renderErrorComponent = () => {
      if (!errorMessage || props.value.length < maxLength) return <></>;

      return (
        <span className={Styles.error}>
          <Boschicon name="bosch-ic-alert-error" />
          <span data-testid="rd-search-input-error-message">
            {errorMessage}
          </span>
        </span>
      );
    };

    const renderInformationComponent = () => {
      if (!maxLength || props.value.length >= maxLength) return <></>;

      return (
        <span className={Styles.information}>
          {t("RDInput_InformationMessage")
            .replace("{currentLength}", props.value.length)
            .replace("{maxLength}", maxLength)}
        </span>
      );
    };

    return (
      <div className={`${Styles.container} ${containerClassName}`}>
        {!!title ? (
          <span className={`text_oversize_handler ${Styles.title}`}>
            {title}
          </span>
        ) : (
          <></>
        )}
        <Input
          ref={ref}
          {...restrictedProps}
          className={`${Styles.rd_search_input} ${
            !!title && Styles.with_title
          } ${props.className ?? ""}`}
          icon={
            <div className={Styles.input_buttons}>
              <Button onClick={onClear} data-testid="rd-search-input-clear-btn">
                <Close />
              </Button>
              {!!onSearch ? (
                <Button
                  onClick={onSearch}
                  data-testid="rd-search-input-search-btn"
                >
                  <Search />
                </Button>
              ) : (
                <></>
              )}
            </div>
          }
        />
        {renderErrorComponent()}
        {renderInformationComponent()}
      </div>
    );
  }
);

export default RDSearchInput;
