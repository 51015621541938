import { useTranslation } from "react-i18next";
import RDMessageDialog from "../RDMessageDialog/RDMessageDialog";

type Props = {
  onClose: () => void;
  messages?: string[];
};

const RDCommonErrorModal = ({ onClose, messages }: Props) => {
  const { t } = useTranslation();

  return (
    <RDMessageDialog
      variant="error"
      title={t("Popup_Title_Error")}
      messages={messages || [t("Error_Common")]}
      confirmLabel={t("CloseButton")}
      onConfirm={onClose}
    />
  );
};

export default RDCommonErrorModal;
